import 'whatwg-fetch';
import { configureRefreshFetch, fetchJSON } from './refresh-fetch';
import merge from 'lodash/merge';
import auth from './auth.service';
import { baseUrl, urls } from '../config';

export const formatQueryParams = params =>
  Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

const fetchJSONWithToken = (url, options = {}, stringify = true, isBlob = false) => {
  const token = auth.getAccessToken();
  const contractorToken = auth.getContractorToken();

  const base = options.baseUrl || baseUrl;
  let optionsWithToken = options;
  const headers = {
    'x-client-timezone-offset': new Date().getTimezoneOffset(),
  };
  if (token != null) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (contractorToken != null) {
    headers['Contractor-Authorization'] = contractorToken;
  }

  optionsWithToken = merge({}, options, {
    headers,
  });

  if (optionsWithToken && optionsWithToken.body && stringify) {
    optionsWithToken.body = JSON.stringify(optionsWithToken.body);
  }

  if (optionsWithToken && optionsWithToken.params) {
    const params = formatQueryParams(optionsWithToken.params);
    url = `${url}?${params}`;
  }

  return fetchJSON(base + url, optionsWithToken, stringify, isBlob);
};

const shouldRefreshToken = error => {
  return error.response.status === 401;
};

const refreshToken = () => {
  const currentRefreshToken = auth.getRefreshToken();
  auth.clearTokens();
  if (!currentRefreshToken) throw new Error();
  return fetchJSONWithToken(urls.auth.refresh, {
    method: 'POST',
    body: { token: currentRefreshToken },
  })
    .then(response => {
      auth.setAccessToken(response.body.accessToken);
      auth.setRefreshToken(currentRefreshToken);
    })
    .catch(error => {
      // Clear token and continue with the Promise catch chain
      auth.clearTokens();
      throw error;
    });
};

const request = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken,
  refreshToken,
});

export default request;
