import React, { Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { muiTheme } from './theme';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter  } from 'react-router-dom';

import i18n from './i18n';
import Routes from './routes';

import { ContractorProvider, AuthProvider, NotificationProvider, InductionProvider } from './context';
import { I18nextProvider } from 'react-i18next';
import Loading from './components/Loading';

function App({ history }) {
  return (
    <Suspense fallback={<Loading />}>
      <AuthProvider>
        <SnackbarProvider hideIconVariant>
          <I18nextProvider i18n={i18n}>
            <ContractorProvider>
              <InductionProvider>
                <NotificationProvider>
                  <ThemeProvider theme={muiTheme}>
                    <CssBaseline />
                    <BrowserRouter history={history}>
                      <Routes />
                    </BrowserRouter>
                  </ThemeProvider>
                </NotificationProvider>
              </InductionProvider>
            </ContractorProvider>
          </I18nextProvider>
        </SnackbarProvider>
      </AuthProvider>
    </Suspense>
  );
}

export default App;
