import React, { createContext, useState, useEffect } from 'react';
import InductionService from '../services/induction.service';

const InductionContext = createContext();

export const InductionProvider = ({ children }) => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const getSteps = async () => {
      const steps = await InductionService.getInductionSteps();
      setSteps(steps);
    };
    getSteps();
  }, []);

  return <InductionContext.Provider value={steps}>{children}</InductionContext.Provider>;
};

export default InductionContext;
