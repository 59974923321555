import React from 'react';
import { Route } from 'react-router-dom';
import Protected from '../container';
import { publicUrls } from '../../../../config';

const ProtectedRouteComponent = ({ children, requiredRoles, requiredAnyRoles, redirectOnFail, ...props }) => {
  return (
    <Route
      {...props}
      render={() => (
        <Protected
          requiredRoles={requiredRoles}
          requiredAnyRoles={requiredAnyRoles}
          saveUrlOnFail
          redirectOnFail={redirectOnFail || `${publicUrls.login}`}
          redirectOnAuthorizationFail={publicUrls.home}
        >
          {children}
        </Protected>
      )}
    />
  );
};

export default ProtectedRouteComponent;
