import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

import './index.css';
import App from './App';

document.addEventListener('DOMContentLoaded', async () => {
  const history = createBrowserHistory();
  ReactDOM.render(<App history={history} />, document.getElementById('root'));
});

ReactDOM.render(<App />, document.getElementById('root'));
