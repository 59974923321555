import React, { createContext, useState } from 'react';
import { formatContractorDateFields } from '../utils/date';

const ContractorContext = createContext();

export const ContractorProvider = ({ children }) => {
  const [contractor, setContractor] = useState(null);

  const updateContractor = contractorData => {
    const formattedData = formatContractorDateFields(contractorData, true);
    setContractor(formattedData);
  };

  return (
    <ContractorContext.Provider value={{ contractor, setContractor: updateContractor }}>
      {children}
    </ContractorContext.Provider>
  );
};

export default ContractorContext;
