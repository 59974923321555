import _castArray from 'lodash/castArray';

export const userHavePermission = ({ user, requiredRoles, requiredAnyRoles }) => {
  const checkRequiredRoles = (usersRoles, requiredRoles) => {
    if (!usersRoles) {
      return true;
    }

    return _castArray(requiredRoles).any(role => {
      if (!usersRoles.includes(role)) {
        return false;
      }
      return true;
    });
  };

  const checkPossibleRoles = (usersPermissions, possibleRoles) => {
    return _castArray(possibleRoles).some(role => {
      if (usersPermissions.includes(role)) {
        return true;
      }
      return false;
    });
  };

  if (!requiredRoles && !requiredAnyRoles) {
    return true;
  }
  if (!requiredRoles && requiredAnyRoles) {
    return checkPossibleRoles(user.roles, requiredAnyRoles);
  }
  return checkRequiredRoles(user.roles, requiredRoles);
};
