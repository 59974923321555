import moment from 'moment';
import { FULL_DATE_FORMAT } from '../constants/time';

const timeZoneOffset = new Date().getTimezoneOffset();

export const getStartOfDayUTC = dateMoment =>
  dateMoment
    .clone()
    .utc()
    .startOf('d')
    .toISOString();

export const momentUTC = () => moment().utc();

export const getBookingTime = date => {
  const mdate = moment(date);
  return mdate.add(mdate.isUtc() ? 0 : timeZoneOffset, 'm').format(FULL_DATE_FORMAT);
};

const formatToUTC = date => {
  return moment(date)
    .utc()
    .subtract(timeZoneOffset, 'm')
    .toISOString();
};

export const formatToLocal = date => {
  return moment(date)
    .utc()
    .add(timeZoneOffset, 'm')
    .toISOString();
};

export const formatContractorDateFields = (contractorData, toLocal) => {
  const dateFields = ['birthDate', 'registrationCompleteDate'];
  const formattedData = { ...contractorData };

  const formatMethod = toLocal ? formatToLocal : formatToUTC;

  dateFields.forEach(field => {
    if (formattedData[field]) {
      formattedData[field] = formatMethod(formattedData[field]);
    }
  });

  return formattedData;
};

export const getFormattedLocalDate = dateString => {
  return moment(dateString)
    .utc()
    .local()
    .format(FULL_DATE_FORMAT);
};
