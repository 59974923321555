// @flow

import { publicUrls } from '../../config';

function configureRefreshFetch(configuration) {
  const { refreshToken, shouldRefreshToken, fetch } = configuration;

  let refreshingTokenPromise = null;

  return (url, options, stringify, isBlob) => {
    if (refreshingTokenPromise !== null) {
      return (
        refreshingTokenPromise
          .then(() => fetch(url, options))
          // Even if the refreshing fails, do the fetch so we reject with
          // error of that request
          .catch(() => fetch(url, options))
      );
    }

    return fetch(url, options, stringify, isBlob).catch(error => {
      if (shouldRefreshToken(error)) {
        if (refreshingTokenPromise === null) {
          refreshingTokenPromise = new Promise((resolve, reject) => {
            refreshToken()
              .then(() => {
                refreshingTokenPromise = null;
                resolve();
              })
              .catch(refreshTokenError => {
                window.location.href = publicUrls.login;
                refreshingTokenPromise = null;
                reject(refreshTokenError);
              });
          });
        }

        return refreshingTokenPromise
          .catch(() => {
            // If refreshing fails, continue with original error
            throw error;
          })
          .then(() => fetch(url, options));
      } else {
        throw error;
      }
    });
  };
}

export default configureRefreshFetch;
