import { createTheme, darken } from '@material-ui/core/styles';

export const headerHeight = 59;

const defaultSettings = {
  // Typography
  typography: {
    fontFamily: 'Nunito, sans-serif',
    h2: {
      fontSize: '40px',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '25px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '18px',
      fontWeight: 'normal',
    },
    body2: {
      fontSize: '15px',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: '20px',
      fontWeight: 'normal',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
  },
  // Colors
  palette: {
    primary: {
      transparent: 'rgba(255, 121, 0, 0.1)',
      light: 'rgba(255, 121, 0, 0.5)',
      main: '#FF7900',
      dark: '#D15734',
    },
    secondary: {
      light: 'rgba(77, 77, 77, 0.5)',
      main: '#EFEFEF',
      dark: '#4D4D4D',
      transparent: 'rgba(77, 77, 77, 0.2)',
      highlyTransparent: 'rgba(77, 77, 77, 0.1)',
    },
    text: {
      primary: '#151E29',
      secondary: 'rgba(77, 77, 77, 0.5)',
    },
    white: {
      main: '#FAFAFA',
      total: '#FFFFFF',
    },
    black: {
      main: '#151E29',
      total: '#000000',
      light: '#4A4A4A',
    },
    error: {
      main: '#EE543D',
      secondary: '#C9382F',
    },
    success: {
      main: '#24D476',
    },
    progress: {
      no: '#C5C2C2',
      middle: '#E7B041',
      completed: '#43CB8E',
    },
    transparent: 'transparent',
  },
};
const defaultMuiTheme = createTheme(defaultSettings);
const { breakpoints } = defaultMuiTheme;

// Overriding of default material UI CSS values

// Inputs
defaultMuiTheme.overrides.MuiOutlinedInput = {
  root: {
    '&.MuiOutlinedInput-root:not(.Mui-error):not(:focus-within)': {
      '&, &:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: defaultSettings.palette.secondary.transparent,
        },
      },
    },
    '&&& $input': {
      padding: '11.1px 10px',
    },
  },
  multiline: {
    padding: 0,
  },
  adornedStart: {
    paddingLeft: '10px',
  },
};

defaultMuiTheme.overrides.MuiInputAdornment = {
  root: {
    cursor: 'auto',
  },
  positionStart: {
    marginRight: '0px',
    paddingRight: '0px',
  },
};

defaultMuiTheme.overrides.MuiInputLabel = {
  asterisk: {
    color: defaultMuiTheme.palette.error.light,
  },
};

// Buttons
defaultMuiTheme.overrides.MuiButton = {
  root: {
    fontSize: '20px',
    fontWeight: 'bold',
    textTransform: 'none',
    textAlign: 'center',
    borderWidth: '1px !important',
    borderRadius: 5,
  },
  label: {
    color: defaultSettings.palette.white.main,
  },
  startIcon: {
    marginLeft: 0,
    marginRight: '5px',
  },
  containedPrimary: {
    '&.Mui-disabled': {
      backgroundColor: `${defaultSettings.palette.primary.main}80`,
    },
  },
  containedSecondary: {
    '& .MuiButton-label': {
      color: `${defaultSettings.palette.secondary.dark}80`,
    },
    '&:hover': {
      backgroundColor: darken(defaultSettings.palette.secondary.main, 0.1),
    },
  },
};

defaultMuiTheme.props.MuiButton = {
  disableElevation: true,
};

defaultMuiTheme.overrides.MuiToggleButton = {
  root: {
    color: defaultSettings.palette.primary.main,
    backgroundColor: `${defaultSettings.palette.white.main} !important`,
    border: `1px solid ${defaultSettings.palette.primary.main} !important`,
  },
  selected: {
    color: `${defaultSettings.palette.white.main} !important`,
    backgroundColor: `${defaultSettings.palette.primary.main} !important`,
  },
};

// Checkbox
defaultMuiTheme.overrides.MuiCheckbox = {
  colorPrimary: {
    color: `${defaultSettings.palette.primary.main} !important`,
  },
};

defaultMuiTheme.overrides.MuiIconButton = {
  root: {
    borderRadius: '5px',
  },
};
defaultMuiTheme.overrides.MuiTableCell = {
  root: {
    padding: '5px',
  },
};

// Overriding Font values for mobile view
defaultMuiTheme.overrides.MuiTypography = {
  h2: {
    [breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  h3: {
    [breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
  h4: {
    [breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  body1: {
    [breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
};

export const muiTheme = defaultMuiTheme;
