import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      backgroundColor: ({ withBackdrop }) => (withBackdrop ? `${theme.palette.white.total}80` : null),
      zIndex: 10,

      '& svg': {
        color: ({ color }) => `${color} !important`,
        fill: ({ color }) => `${color} !important`,
      },
      '& path': {
        color: ({ color }) => `${color} !important`,
        fill: ({ color }) => `${color} !important`,
      },
    },
  }),
  { name: 'Loading' },
);
