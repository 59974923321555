import request from './request.service';
import { urls } from '../config';

export const getProfile = async (isContractor = false) => {
  try {
    return await request(isContractor ? urls.induction.me : urls.users.me, {
      method: 'GET',
    });
  } catch (error) {
    return null;
  }
};

export const getUserStatistics = async id => {
  return request(urls.users.statisticsById(id), { method: 'GET' });
};

export const getUserById = async userId => {
  return request(urls.users.byId(userId));
};
