import _isEmpty from 'lodash/isEmpty';

import request from './request.service';
import urlRestoringService from './urlRestoring.service';
import { publicUrls, urls } from '../config';

const ACCESS_TOKEN_KEY = 'accessToken';
const CONTRACTOR_TOKEN_KEY = 'contractorToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

const parse = JSON.parse;
const stringify = JSON.stringify;

const auth = {
  /**
   * Remove an item from the used storage
   * @param  {String} key [description]
   */
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key);
    }

    return null;
  },

  /**
   * Clear all app storage
   */
  clearAppStorage() {
    if (localStorage) {
      localStorage.clear();
    }

    if (sessionStorage) {
      sessionStorage.clear();
    }
  },

  clearTokens() {
    auth.clear(ACCESS_TOKEN_KEY);
    auth.clear(REFRESH_TOKEN_KEY);
  },

  /**
   * Returns data from storage
   * @param  {String} key Item to get from the storage
   * @return {String|Object}     Data from the storage
   */
  get(key) {
    if (localStorage && localStorage.getItem(key)) {
      return parse(localStorage.getItem(key)) || null;
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return parse(sessionStorage.getItem(key)) || null;
    }

    return null;
  },
  /**
   * Set data in storage
   * @param {String|Object}  value    The data to store
   * @param {String}  key
   * @param {Boolean} isLocalStorage  Defines if we need to store in localStorage or sessionStorage
   */
  set(value, key, isLocalStorage) {
    if (_isEmpty(value)) {
      return null;
    }

    if (isLocalStorage && localStorage) {
      return localStorage.setItem(key, stringify(value));
    }

    if (sessionStorage) {
      return sessionStorage.setItem(key, stringify(value));
    }

    return null;
  },

  getAccessToken() {
    return auth.get(ACCESS_TOKEN_KEY);
  },

  getContractorToken() {
    return auth.get(CONTRACTOR_TOKEN_KEY);
  },

  getRefreshToken() {
    return auth.get(REFRESH_TOKEN_KEY);
  },

  setRefreshToken(value = '', isLocalStorage = true) {
    return auth.set(value, REFRESH_TOKEN_KEY, isLocalStorage);
  },

  setAccessToken(value = '', isLocalStorage = true) {
    return auth.set(value, ACCESS_TOKEN_KEY, isLocalStorage);
  },

  setContractorToken(value = '', isLocalStorage = true) {
    return auth.set(value, CONTRACTOR_TOKEN_KEY, isLocalStorage);
  },

  async login(email = null, password = null) {
    if (!email || !password) {
      throw new Error('Provide a Username or Password!');
    }
    auth.clearTokens();

    const result = await request(urls.auth.login, {
      method: 'POST',
      body: {
        email,
        password,
      },
    });

    auth.setAccessToken(result.body.accessToken);
    auth.setRefreshToken(result.body.refreshToken);

    window.location.href = publicUrls.admin;
  },

  async register(login = null, password = null, fullName = null) {
    if (!login || !password || !fullName) {
      throw new Error('Provide a Username or Password or Full name!');
    }
    auth.clearTokens();

    const result = await request(urls.auth.register, {
      method: 'POST',
      body: {
        email: login,
        password,
        fullName,
      },
    });

    auth.setAccessToken(result.body.accessToken);
    auth.setRefreshToken(result.body.refreshToken);

    window.location.href = urlRestoringService.getUrl();
  },

  async checkUserAuth() {
    try {
      const result = await request(urls.users.me, { method: 'GET' });

      return !!result;
    } catch (err) {
      return false;
    }
  },

  async logout() {
    auth.clearTokens();
    window.location.href = publicUrls.login;
  },

  async changePassword(oldPassword, newPassword) {
    try {
      await request(urls.auth.changePassword, {
        method: 'PUT',
        body: {
          oldPassword,
          newPassword,
        },
      });
    } catch (err) {
      throw new Error(err.body?.message);
    }
  },
};

export default auth;
