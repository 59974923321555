import request from './request.service';
import { urls } from '../config';
import { formatContractorDateFields } from '../utils/date';

const InductionService = {
  steps: null,
  async setProgress(status, maxStep) {
    try {
      const response = await request(urls.induction.progress, { method: 'PUT', body: { status, m: maxStep } });
      return response.body;
    } catch (err) {
      return null;
    }
  },

  async sendPersonalData(personalData) {
    try {
      const formattedPersonalData = formatContractorDateFields(personalData);

      const response = await request(urls.induction.personalData, { method: 'PUT', body: formattedPersonalData });
      return response.body;
    } catch (err) {
      return null;
    }
  },

  async getInductionSteps() {
    if (!this.steps) {
      const lang = window.LANG || 'en';

      const response = await fetch(urls.induction.steps(lang));
      this.steps = await response.json();
    }
    return this.steps;
  },

  async getQuizQuestions(fileName) {
    try {
      const response = await request(urls.induction.quizByFileName(fileName), { method: 'GET' });
      return response.body;
    } catch (err) {
      return null;
    }
  },

  async getQuizResults(answers, fileName) {
    try {
      const response = await request(urls.induction.quizResults, {
        method: 'POST',
        body: { answers, fileName },
      });
      return response.body;
    } catch (err) {
      return null;
    }
  },
};

export default InductionService;
