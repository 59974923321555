const baseUrl = process.env.REACT_APP_BASE_URL;

module.exports = {
  urls: {
    auth: {
      login: '/api/auth/login',
      register: '/api/auth/register ',
      logout: '/api/auth/logout',
      refresh: '/api/auth/token',
      changePassword: '/api/auth/change-password',
    },
    users: {
      me: '/api/users/me',
    },
    induction: {
      me: '/api/induction/me',
      quizByFileName: fileName => `/api/induction/quiz/?fileName=${fileName}`,
      quizResults: '/api/induction/quizResults',
      progress: '/api/induction/progress',
      personalData: '/api/induction/personalData',
      finish: '/api/induction/finish',
      steps: lang => `/assets/data/${lang}/steps4.json`,
    },
    bookings: {
      all: '/api/bookings',
      me: '/api/bookings/me',
      available: '/api/bookings/available',
    },
    contractors: {
      invite: '/api/contractors/invite',
      resendInvite: '/api/contractors/resend-invite',
      cdmInvite: id => `/api/contractors/${id}/cdm-invite`,
      withParams: params => `/api/contractors/?${params}`,
      byId: id => `/api/contractors/${id}`,
      addToArchive: id => `/api/contractors/${id}/add-to-security-system`,
    },
    attachments: {
      types: '/api/attachmentTypes',
      upload: type => `/api/attachments/upload/${type}`,
      my: '/api/attachments/me',
      byId: id => `/api/attachments/${id}`,
      previewById: id => `/api/attachments/${id}/preview`,
      download: id => `/api/attachments/${id}/download`,
    },
    export: {
      excel: {
        contractors: '/api/export/excel/contractors',
      },
    },
  },

  publicUrls: {
    home: '/',
    admin: '/admin',
    login: '/login',
  },

  baseUrl,
};
