import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import UrlRestoringService from '../../../../services/urlRestoring.service';
import authContext from '../../../../context/authContext';
import { getProfile } from '../../../../services/user.service';
import { userHavePermission } from './userHavePermission';

import Loading from '../../../Loading';

const ProtectedContainerComponent = ({
  requiredRoles,
  requiredAnyRoles,
  redirectOnFail,
  saveUrlOnFail,
  fallback,
  redirectOnAuthorizationFail,
  children,
}) => {
  const { auth, setAuth } = useContext(authContext);
  const { user, pending, finished } = auth;

  const authRequestWasSent = () => pending || finished;

  const userIsAuthenticated = () => finished && user !== null;

  const userIsAuthorized = () => userHavePermission(user, requiredRoles, requiredAnyRoles);

  const ensureAuthRequestWasSent = async () => {
    if (!authRequestWasSent()) {
      if (redirectOnFail) {
        UrlRestoringService.setRedirectUrl(redirectOnFail);
      }
      try {
        setAuth({ ...auth, pending: true });
        const userProfile = await getProfile();
        setAuth({ user: userProfile, pending: false, finished: true });
      } catch (e) {
        setAuth({ user: null, pending: false, finished: true });
      }
    }
  };

  useEffect(() => {
    ensureAuthRequestWasSent();
  });

  const renderAuthFailure = () => {
    if (saveUrlOnFail) {
      UrlRestoringService.setUrl(window.location.pathname);
    }

    if (redirectOnFail) {
      window.location.href = redirectOnFail;
    }

    return fallback || null;
  };

  if (!authRequestWasSent() || pending) {
    return <Loading />;
  }

  if (!userIsAuthenticated()) {
    return renderAuthFailure();
  }

  if (!userIsAuthorized() && redirectOnAuthorizationFail) {
    return <Redirect to={redirectOnAuthorizationFail} />;
  }

  if (!userIsAuthorized()) {
    return renderAuthFailure();
  }

  return children;
};

export default ProtectedContainerComponent;
