import React, { createContext } from 'react';
import { useSnackbar } from 'notistack';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = (message, options = {}) => {
    enqueueSnackbar(message, options);
  };

  return <NotificationContext.Provider value={showNotification}>{children}</NotificationContext.Provider>;
};

export default NotificationContext;
