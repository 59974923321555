import React from 'react';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';

import ProtectedRoute from '../components/common/protected/route';
import Loading from '../components/Loading';

import { publicUrls } from '../config';

const { login, admin } = publicUrls;

const Admin = loadable(() => import('../containers/Admin'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Induction = loadable(() => import('../containers/Induction'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Login = loadable(() => import('../containers/Login'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

function Routes() {
  return (
    <Switch>
      <ProtectedRoute exact path={admin} redirectOnFail={login} saveUrlOnFail>
        <Admin />
      </ProtectedRoute>
      <Route exact path={login} saveUrlOnFail>
        <Login />
      </Route>
      <Route exact path="*" saveUrlOnFail>
        <Induction />
      </Route>
    </Switch>
  );
}

export default Routes;
