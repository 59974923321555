import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import { BUILD_HASH } from './constants/hash';

const lng = window.LANG || process.env.REACT_APP_LANG || 'en';

import moment from 'moment';
import 'moment/locale/de';

moment.updateLocale('de', {
  week: {
    dow: 0,
  },
});

moment.locale(lng);

// eslint-disable-next-line
i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng,
    fallbackLng: 'en',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
      format: function(value, format) {
        if (format === 'capitalize') return value.charAt(0).toUpperCase() + value.slice(1);
        return value;
      },
    },

    detection: {
      order: ['customDetector', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    },

    backend: {
      loadPath: `/locales${BUILD_HASH}/{{lng}}/labels.json`,
      allowMultiLoading: false,
    },

    react: {
      useSuspense: true,
    },
  });

export default i18n;
