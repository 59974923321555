export const CONTRACTORS_ARRIVAL_TIME_FREQUENCY = 60; //in minutes

export const HOURS_AND_MINUTES_FORMAT = 'HH:mm';
export const START_OF_DAY_STRING = '00:00';
export const DAY_MONTH_YEAR_FORMAT = 'DD MMM YYYY';
export const DATE_WITH_TIME_FORMAT = 'DD MMM YYYY, HH:mm';
export const FULL_DATE_FORMAT = 'DD MMM YYYY HH:mm';

export const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy';

export const TIME_SLOTS_EMPTY_BASE = new Array(24).fill([]);

export const INVALID = 'Invalid Date';
